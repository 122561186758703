import { HandForward, Hotline } from '@/assets/icons';
import { GetAllHotTagsParams } from '@/constants/defaultParams';
import { useGetAllTagsQuery } from '@/generated/graphql';
import useGetSeoConfig from '@/hooks/useGetSeoConfig';
import Link from 'next/link';
import { useMemo } from 'react';

const HotNews = () => {
  const { data } = useGetAllTagsQuery({
    variables: GetAllHotTagsParams,
  });

  const hotTags = useMemo(() => data?.tags?.slice(0, 3), [data]);

  const { phoneNumber } = useGetSeoConfig();

  return (
    <div
      style={{
        animation: 'animate-moveinright 0.5s ease-in-out',
      }}
      className="border-y-[1px] border-[#b8b8b8] py-3 flex gap-2 justify-between items-center overflow-x-auto no-scrollbar"
    >
      <div className="flex gap-16 items-center">
        <p className="text-xs font-black whitespace-nowrap text-DarkBlue px-4 py-2 bg-Silver rounded-md h-fit w-auto">
          Tin nóng
        </p>

        <div className="flex lg:flex-1 gap-2">
          {hotTags?.map((tag, index) => {
            return (
              <Link href={`/tim-kiem?keyword=${tag.name}`} key={index}>
                <h2 className="text-xs font-medium whitespace-nowrap text-DarkBlue px-4 py-2 bg-Silver rounded-md">
                  {tag.name}
                </h2>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="hidden lg:flex py-1 items-center bg-LightGray rounded-md shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
        <Link
          href={`tel:${phoneNumber}`}
          className="flex w-max gap-2 items-center px-4 text-xs font-black text-DarkBlue min-w-[115px] justify-center hover:opacity-80"
        >
          <Hotline />
          Hotline
        </Link>
        |
        <Link
          href="/quang-cao"
          className="flex w-max gap-2 items-center px-4 text-xs font-black text-DarkBlue min-w-[115px] justify-center hover:opacity-80"
        >
          <HandForward />
          Quảng cáo
        </Link>
      </div>
    </div>
  );
};

export default HotNews;
