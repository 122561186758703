import dynamic from 'next/dynamic';
import { SVGProps } from 'react';

export const SearchIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./search.svg'),
);
export const AccountIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./account.svg'),
);
export const CommentUserIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./comment-user.svg'),
);
export const HeartIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./heart.svg'),
);
export const HistoryIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./history.svg'),
);
export const StarIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./star.svg'),
);
export const Logout = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./logout.svg'),
);
export const HomeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./home.svg'),
);
export const Hotline = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./hotline.svg'),
);
export const HandForward = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./handForward.svg'),
);
export const PrevArrow = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./prev.svg'),
);
export const NextArrow = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./next.svg'),
);
export const DownArrow = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./downArrow.svg'),
);
export const FbIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./facebook.svg'),
);
export const InsIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./insta.svg'),
);
export const YtIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./youtube.svg'),
);
export const LikeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./like.svg'),
);
export const BlackLikeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./likeBlack.svg'),
);
export const GgNews = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./ggnews.svg'),
);
export const TagIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./tag.svg'),
);
export const CopyIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./copy.svg'),
);
export const PrintIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./print.svg'),
);
export const SaveIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./saved-post.svg'),
);
export const CommentIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./comment.svg'),
);
export const ZoomInIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./zoom-in.svg'),
);
export const ZoomOutIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./zoom-out.svg'),
);
export const Hamburger = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./hamburger.svg'),
);
export const FbFooterIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./fbFooter.svg'),
);
export const InsFooterIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./insFooter.svg'),
);
export const YtFooterIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./ytFooter.svg'),
);
export const LikedIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./liked.svg'),
);
export const ToolIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./tool.svg'),
);
